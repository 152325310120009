import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, TextField, Typography } from '@mui/material'
import { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Unknown from '../../../../assets/images/home/unknown-reason.png'
import { ToolContext } from '../../../../core/context/ToolContext'
import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';
import { Close } from '../../../../core/global/Icons';
import { SnakeLoader } from '../../../../core/loader/SnakeLoader'
import { CircleCheck } from '../../../../core/global/Icons';
import { S3v2 } from '../../../../core/transmit/S3';
import { SetImage } from './SetImage';
let hasValue = [];
export const Reasons = ({__SESSION, pslztn}) => {
    const q = new URLSearchParams(useLocation().search);
    const history = useHistory()
    const { home_state } = useContext(ToolContext)
    const myReasons = home_state.myReasons
    const [image, setImage] = useState({location: null, name: '', type: '', files: null})
    const [hasError, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const handleClose = () => {
        setOpen(false);
    }
    const handleChange = (e) => {
        const {name, value} = e.target
        myReasons.set({...myReasons.data, reasons: {...myReasons.data.reasons, [name]: value}});
        if(value === ''){
            let index = hasValue.indexOf(name);
            if (index > -1) { 
                hasValue.splice(index, 1); 
            }
        }else{
            let filterValues = hasValue.filter(f => f === name);
            if(filterValues.length === 0){
                hasValue.push(name)
            }
        }
        if(hasValue.length === 3){
            setDisabled(false)
        }else{
            setDisabled(true)
        }
    }
    const handleSubmit = async() => {
        if(myReasons.data.reasons.first_reason === '' || myReasons.data.reasons.second_reason === '' || myReasons.data.reasons.third_reason === ''){
            setError(true);
        }else{
            handleSave();
        }
    }
    const handleSave = async() => {
        setLoading(true)
        let location = (image.location === null ? (myReasons.data.image === null ? Unknown : myReasons.data.image[0].link) : image.location);
        if(image.files !== null){
            const transmit  = await S3v2(image.files)
            location = transmit.location
        }
        let submit = await rqx.post(`<<tool>>/${env()}/userReason/create`, {aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), first: myReasons.data.reasons.first_reason, second: myReasons.data.reasons.second_reason, third: myReasons.data.reasons.third_reason, location: location, name: image.name, type: image.type, from: 'sls'})
        if(submit.msg === 'success'){
            setOpen(true);
            myReasons.set({...myReasons.data, isLoading: false, image: (image.location === null ? null : [{link: location}])})
            history.push('/biz')
            setOpen(false);
            pslztn.set(null)
        }
    }
    useEffect(() => {
        if(myReasons.data.reasons.length === 0){
            setDisabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Box>
            <Dialog open={true} PaperProps={{ sx: { borderRadius: "12px", width:'454px'}}}>
                {
                    update ? (
                        <>
                        <DialogTitle id="alert-dialog-title"></DialogTitle>
                        <DialogContent>
                            <Box mt="20px" color="#000" fontSize="14px" textAlign="center">Would you like to update your deepest why's?</Box>
                        </DialogContent>
                        <DialogActions sx={{width: '100%', textAlign: 'right', pb: '24px', pr: '24px', display: 'flex'}}>
                            <Button variant="outlined" disabled={loading ? true : false} onClick={()=>setUpdate(false)}>No</Button>
                            {
                                loading ? (
                                    <Box bgcolor="#11783c" borderRadius="6px" p="6px 15px">
                                        <Box height="25px" display="flex" alignItems="center" justifyContent="center"><SnakeLoader size="10px" bg="#fff" distance="0.3rem" /></Box>
                                    </Box>
                                ) : (
                                    <Button variant="contained" color="primary" onClick={handleSave}>Yes</Button>
                                )
                            }
                        </DialogActions>
                        </>
                    ) : (
                        <>
                        <DialogTitle sx={{fontSize:'20px', fontWeight:'600', lineHeight: '30px', display: 'flex', justifyContent: 'space-between'}}>
                            {
                                q.get('action') === null ? (
                                    'Knowing your deepest "why" is an important first step in figuring out on how to pass your CPA board exam.'
                                ) : (
                                    <>
                                    <Box>
                                        Knowing your deepest "why" is an important first step in figuring out on how to pass your CPA board exam.
                                    </Box>
                                    <Box><IconButton sx={{border: '1px solid #E5EBF6', borderRadius: '6px', padding: '3px'}} onClick={()=>history.push('/psl')}><Close sx={{color: '#0070EF'}}/></IconButton></Box>
                                    </>
                                )
                            }
                        </DialogTitle>
                        <DialogContent sx={{height: '550px'}}>
                            <Box display="flex" justifyContent="center" position="relative">
                                {
                                    loading && 
                                    <Box bgcolor="rgba(255, 255, 255, 0.56)" position='absolute' height="100%" display="flex" sx={{width: '100%', zIndex: 1}} justifyContent="center" alignItems="center" borderRadius="12px" >
                                    </Box>
                                }
                                <Box>
                                    <Typography fontSize="16px" fontWeight={600} color="#313131" mb="16px">Image Inspiration</Typography>
                                    <Box fontSize="14px" fontWeight={400} mb="10px">You can add an image that inspired you to pass your board exam.</Box>
                                    <SetImage setImage={setImage} image={image} myReasons={myReasons.data}/>
                                    <Typography variant="subtitle2" color="#525B6D" pb={2}>Share your top 3 reason on why you want to become a CPA.</Typography>
                                    <Box pr={3} fontWeight="400" fontSize="14px">1st Reason</Box>
                                    <TextField fullWidth margin="dense" size="small" name="first_reason" value={myReasons.data.reasons === null ? '' : myReasons.data.reasons.first_reason} onChange={(e)=>handleChange(e)} helperText={hasError && myReasons.data.reasons.first_reason === '' ? 'This field is required' : ''}/>
                                    <Box pr={3} fontWeight="400" fontSize="14px">2nd Reason</Box>
                                    <TextField fullWidth margin="dense" size="small"  name="second_reason" value={myReasons.data.reasons === null ? '' : myReasons.data.reasons.second_reason}  onChange={(e)=>handleChange(e)} />
                                    <Box pr={3} fontWeight="400" fontSize="14px">3rd Reason</Box>
                                    <TextField fullWidth margin="dense" size="small"  name="third_reason" value={myReasons.data.reasons === null ? '' : myReasons.data.reasons.third_reason}  onChange={(e)=>handleChange(e)} />
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions sx={{padding:'24px'}}>
                                {
                                    loading ? (
                                        <Box bgcolor="#11783c" borderRadius="6px" p="6px 15px">
                                            <Box height="25px" display="flex" alignItems="center" justifyContent="center"><SnakeLoader size="10px" bg="#fff" distance="0.3rem" /></Box>
                                        </Box>
                                    ) : (
                                        <Button variant="contained" color="primary" sx={{padding: '6px 24px'}} onClick={handleSubmit} disabled={disabled}>
                                            {q.get('action') === null ? 'Submit' : 'Update'}
                                        </Button>
                                    )
                                }
                        </DialogActions>
                        </>
                    )
                }
            </Dialog>
            <Snackbar open={open} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} autoHideDuration={6000} onClose={handleClose}>
                <Alert variant="filled"  severity="success" icon={<CircleCheck fontSize="inherit" fill="#008F5D"/>} sx={{ width: '100%', color: '#008F5D', bgcolor: '#E8F5E9', border: '1px solid #008F5D'}}>
                    {q.get('action') === null ? 'Adding inspiration was successful.' : 'Goal has been updated successfully.'}
                </Alert>
            </Snackbar>
        </Box>
    )
}