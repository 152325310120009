import { useHistory } from 'react-router-dom'
import { Box, Button, Dialog, Typography } from '@mui/material'
import WelcomeImg from '../../../../assets/images/home/welcomev2.png'
export const Welcome = ({pslztn}) => {
    const history = useHistory()
    const handleSetup = () => {
        history.push('/biz/profiling?account=setup')
        pslztn.set(null)
    }
    return (
        <Dialog open={true} PaperProps={{sx:{borderRadius: '16px', width:'400px', p: '24px 25px'}}} >
            <img src={WelcomeImg} alt="icon"/>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                <Typography color="#333333" fontSize="24px" fontWeight={700} mb="24px">Welcome to SUREiPASS</Typography>
                <Typography textAlign="center" fontWeight={400} color="#6A6A6A" mb="24px">Make your learning experience effective and accessible Get a higher probability to pass the board exam with our unique technique.</Typography>
                <Button variant="contained" sx={{padding: '10px 24px', m: 2}} onClick={() => handleSetup()}>Setup your account</Button>
            </Box>
        </Dialog>
    )
}