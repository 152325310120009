import { Box, Button, Dialog, Stack, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import cafgu from '../../../../assets/images/cafgu/Welcome.png'
export const Success = ({__SESSION, pslztn}) => {
    const history = useHistory()
    const handleClose = async() => {
        history.push('/biz')
        pslztn.set(null)
    }
    const handleContinue = () => {
        pslztn.set('tour');
    }
    return (
            <Dialog open={Boolean(true)} PaperProps={{sx:{borderRadius: '16px', width:'420px', p: '24px'}}}>
                <Box textAlign="center" mb="34px">
                    <img src={cafgu} alt="icon" style={{width: '200px'}}/>
                </Box>
                <Typography color="#313131" fontSize="20px" fontWeight={600} textAlign="center" mb="16px">Hi I’m, Cafgu!</Typography>
                <Typography color="#313131" textAlign="center" mb="24px">Welcome {__SESSION.pinfo[0].first_name}, Let me show you some great features of SUREiPASS</Typography>
                <Box display="flex" justifyContent="flex-end">
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" onClick={handleClose} sx={{border: '1px solid #E5EBF6', color: '#313131'}}>Skip</Button>
                        <Button variant="contained" onClick={handleContinue}>Continue</Button>
                    </Stack>
                </Box>
            </Dialog>
    )
}