import { Box, Button, Drawer, IconButton, InputLabel, MenuItem, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { fetchAQReport, fetchProgressReport } from '../../Functions';
import { SnakeLoader } from '../../../../../../core/loader/SnakeLoader'
import { Close } from '../../../../../../core/global/Icons';
import { VM } from '../../../../../../core/VM';
let view = VM();
export const Settings = ({aqReport, setaqReport, __SESSION, setSettings, settings, setPrsp, prsp}) => {
    const [hasError, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState({interval: 'days', month: new Date(), day: new Date(), year: new Date(), quarter: '1', sequence: 'succeeding'});
    const intervals = [{label: 'Daily', value: 'days'},{label: 'Weekly', value: 'weeks'},{label: 'Monthly', value: 'months'},{label: 'Quarterly', value: 'quarter'},{label: 'Yearly', value: 'years'}];
    const quarters = [{value: '1',label: '1st Quarter'},{value: '2',label: '2nd Quarter'},{value: '3',label: '3rd Quarter'},{value: '4',label: '4th Quarter'}];
    const sequences = [{label: '4 Succeeding '+input.interval, value: 'succeeding'},{label: '4 Previous '+input.interval, value: 'previous'}]
    const handleValidation = () => {
        let isValid = true;
        if(input.interval === '' || input.sequence === ''){
            isValid = false;
        }
        return isValid;
    }
    const handleGenerate = () => {
        if(handleValidation()){
            setLoading(true);
            const date_start = null;
            let date = input.year.getUTCFullYear()+'-'+(input.month.getMonth() + 1)+'-'+input.day.getUTCDate();
            console.log(date)
            if(input.interval === 'quarter'){
                let qm = [input.year.getUTCFullYear()+'-01-01', input.year.getUTCFullYear()+'-04-01', input.year.getUTCFullYear()+'-07-01', input.year.getUTCFullYear()+'-10-01']
                date_start = (input.sequence === 'previous' ? moment(qm[input.quarter - 1]).subtract(9, 'months').format('Y-MM-DD') : qm[input.quarter - 1]);
            }else{
                let fetchD = (input.interval === 'months' || input.interval === 'years' ? moment(date).startOf(input.interval).format('Y-MM-DD') : moment(date).format('Y-MM-DD'));
                date_start = (input.sequence === 'previous' ? moment(fetchD).subtract(3, input.interval).format('Y-MM-DD') : fetchD);
            }
            let intervalLabel = intervals.filter(f => f.value === input.interval)[0]
            if(settings.for === 'AQR'){
                setaqReport({...aqReport, stat: 'loading'})
                fetchAQReport({aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), from: 'setting', label: intervalLabel.label, interval: input.interval, sequence: input.sequence, date: date, quarter: input.quarter, status: 'has-exam'}, aqReport, setaqReport)
            }else{
                setPrsp({...prsp, stat: 'loading'})
                fetchProgressReport({aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), licensure_id: __SESSION.licensure, from: 'setting', label: intervalLabel.label, interval: input.interval, sequence: input.sequence, date: date, quarter: input.quarter, status: 'has-exam'}, setPrsp, (view !== '' ? 'mobile' : 'desktop'))
            }
            setTimeout(async() => {
                setSettings({open: false, for: ''})
            }, 2000);
        }else{
            setError(true);
        }
    }
    useEffect(() => {
        let interval = (settings.for === 'AQR' ? aqReport.interval : prsp.interval)
        let sequence = (settings.for === 'AQR' ? aqReport.sequence : prsp.sequence)
        let date = (settings.for === 'AQR' ? aqReport.date : prsp.date)
        let takeInterval = intervals.filter(f => f.label === interval)
        let takeSequence = sequences.filter(f => f.value === sequence)
        setInput({...input, interval: takeInterval[0].value, month: new Date(date), day: new Date(date), year: new Date(date), quarter: (settings.for === 'AQR' ? aqReport.quarter : prsp.quarter), sequence: takeSequence[0].value})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Drawer anchor={'right'} open={settings.open} sx={{"& .MuiDrawer-paper": {overflowY: "visible", mt: "0px"}, transition: 'width 0.3s ease 0s', zIndex: 1202}}>
            <Box position="absolute" left="-60px" width="44px" height="44px" bgcolor="#fff" display="flex" justifyContent="center" alignItems="center" borderRadius="50px"  sx={{top: '10px'}}>
                <IconButton onClick={()=>setSettings({open: false, for: ''})}>
                    <Close />
                </IconButton>
            </Box>
            <Box width="340px" mt={"0px"}>
                <Box pt="24px" pl="16px" pr="16px">
                    <Box mb="5px">
                        <InputLabel sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Interval</InputLabel>
                        <TextField select value={input.interval} onChange={(event)=>{setInput({...input, interval: event.target.value, sequence: ''})}} fullWidth helperText={hasError && input.interval === '' ? 'This field is required' : ''}>
                            {
                                intervals.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Box>
                    <Box mb="5px">
                        <Box sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Date From:</Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box display="flex" width="100%">
                                {
                                    input.interval === 'quarter' && 
                                    <Box mr={1}>
                                        <InputLabel sx={{fontSize: '14px', color: '#333333', }}>Quarter</InputLabel>
                                        <TextField id="outlined-select-currency" select  value={input.quarter} onChange={(event)=>{setInput({...input, quarter: event.target.value})}} helperText={hasError && input.quarter === '' ? 'This field is required' : ''} >
                                        {quarters.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                        </TextField>
                                    </Box>
                                }
                                {
                                    (input.interval === 'days' || input.interval === 'weeks' || input.interval === 'months') &&
                                    <Box mr={1}>
                                        <InputLabel sx={{fontSize: '14px', color: '#333333'}}>Month</InputLabel>
                                        <DatePicker  openTo="month" views={['month']} inputFormat="MMMM" value={input.month} onChange={(e)=>{setInput({...input, month: e, day: e})}} renderInput={(params) => <TextField {...params}  margin="dense"/>} disableMaskedInput />
                                    </Box>
                                }
                                {
                                    (input.interval === 'days' || input.interval === 'weeks') &&
                                    <Box mr={1}>
                                        <InputLabel sx={{fontSize: '14px', color: '#333333'}}>Day</InputLabel>
                                        <DatePicker openTo="day" views={['day']} inputFormat="d" value={input.day} onChange={(e)=>{setInput({...input, day: e})}} renderInput={(params) => <TextField {...params} margin="dense"/>} />
                                    </Box>
                                }
                                <Box width="100%">
                                    <InputLabel sx={{fontSize: '14px', color: '#333333'}}>Year</InputLabel>
                                    <DatePicker openTo="year" views={['year']} inputFormat="Y" value={input.year} onChange={(e)=>{setInput({...input, year: e})}} renderInput={(params) => <TextField {...params} margin="dense" fullWidth/>}
                                        />
                                </Box>
                            </Box>
                        </LocalizationProvider>
                    </Box>
                    <Box mb="24px">
                            <InputLabel sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Sequence</InputLabel>
                            <TextField id="outlined-select-currency" select value={input.sequence} onChange={(e)=>{setInput({...input, sequence: e.target.value})}} fullWidth helperText={hasError && input.sequence === '' ? 'This field is required' : ''}>
                                {
                                    sequences.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                    ))
                                }
                            </TextField>
                    </Box>
                    <Button variant="contained" sx={{width: '100%'}} onClick={handleGenerate}>
                        {
                            loading ? (
                                <Box height="25px" display="flex" alignItems="center" justifyContent="center"><SnakeLoader size="10px" bg="#fff" distance="0.3rem" /></Box>
                            ) : (
                                'Generate'
                            )
                        }
                        
                    </Button>
                </Box>
            </Box>
        </Drawer>
    )
}