import { useState } from "react"

export const ToolStates = () => {
    const [account, setAccount] = useState({lastname: '', firstname: '', middlename: '', suffix: '', profile: '', civil_status: '', gender: '', birthdate: ''})
    const [preload, setPreload] = useState({loader:false, logo:''}) 
    const [prefetch, setPrefetch] = useState(false)
    const [__SESSION, setSession] = useState({ainfo: null, pinfo: null, licensure: null})
    const [toolOwned, setToolOwned] = useState([]);
    return {
        account: {data: account, set: setAccount},
        preload: {data: preload, set: setPreload},
        prefetch: {data: prefetch, set: setPrefetch},
        __SESSION: {data: __SESSION, set: setSession},
        toolOwned: {data: toolOwned, set: setToolOwned},
    }
}