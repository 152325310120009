import { Box, Button, TextField, Typography } from '@mui/material'
import { useState } from 'react';
import { useHistory } from 'react-router-dom'
import Unknown from '../../../assets/images/home/unknown-profile.png'
import { avatarstyle } from '../../../core/styling/global'
import { UploadImage1 } from '../../../core/global/Icons'
export const Setup = ({account, profile, setProfile}) => {
    const history = useHistory()
    const [error, setError] = useState(false);
    const [disable, setDisable] = useState(true)
    const handleNext = () => {
        if(account.data.lastname !== '' && account.data.firstname !== ''){
            history.push('/biz/profiling?account=review');
        }else{
            setError(true)
        }
    }
    const handleChange = (e) => {
        const {name, value} = e.target
        account.set({...account.data, [name] : value})
        let validate = Object.keys(account.data).filter(key => {
            return account.data[key]
        })
        if(validate.length === 2){
            setDisable(false);
        }
    }
    const updateProfile = async(e) => {
        let file = [];
        file = e.target.files[0];
        if(file === undefined){
            
        }else{
            setProfile({...profile, location: URL.createObjectURL(file), files: e.target.files})
        }
    }
    return (
        <>
            <Typography color="#333333" fontSize="24px" fontWeight="bold" py={2}>Setup your account</Typography>
            <Typography color="#525B6D" fontSize="14px">Complete your account set up by providing your personal information.</Typography>
            <Box borderRadius="8px" bgcolor="#FFFFFF" display='flex' justifyContent='center' alignItems='center' flexDirection='column' maxWidth="509px">
                <Box className={avatarstyle(account.data.profile === '' ? (profile.location === null ? Unknown : profile.location) : account.data.profile, 120, 120)} mt="40px"/>
                <Box borderRadius="8px" ml="70px" mt="-25px" overflow="hidden" className="c-pointer" width="32px" >                    
                    <label htmlFor="prf" className="c-pointer">
                        <Box bgcolor="#fff" p="6px" boxShadow="0px 2px 4px 0px #3D77E914" borderRadius="100px" display="flex" justifyContent="center">
                            <input type="file" id="prf" hidden name="profile" accept="image/*" style={{display: 'none'}} onChange={(e)=>updateProfile(e)} />
                            <UploadImage1/>
                        </Box>
                    </label>
                </Box>
                <Box mt="40px" width="370px">
                    <Box fontSize="14px">Last Name*</Box>
                    <TextField fullWidth margin="dense" onChange={(e)=>handleChange(e)} name="lastname" error={account.data.lastname === '' && error ? true : false} helperText={account.data.lastname === '' && error ? 'This field is required' : ''} size="small"/>
                    <Box fontSize="14px" mt="6px">First Name*</Box>
                    <TextField fullWidth margin="dense" onChange={(e)=>handleChange(e)} name="firstname" error={account.data.firstname === '' && error ? true : false} helperText={account.data.firstname === '' && error ? 'This field is required' : ''} size="small"/>
                    <Box fontSize="14px" mt="6px">Suffix</Box>
                    <TextField fullWidth margin="dense" onChange={(e)=>handleChange(e)} name="suffix" size="small"/>
                    <Box display="flex" justifyContent="center">
                        <Button variant="contained" color="primary" sx={{padding: '8px 24px', mt:'24px'}} onClick={() => handleNext()} disabled={disable}>Next</Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}