import Joyride from 'react-joyride'
import React from 'react';
import { useHistory } from 'react-router-dom'
import { useState, useContext } from 'react';
import { ToolContext } from '../../../../../core/context/ToolContext';
import { env } from '../../../../../core/Env';
import { rqx } from '../../../../../core/request/API';
import { showSteps } from './Steps'
export const Index = ({pslztn}) => {
    const { tool_state } = useContext(ToolContext)
    const __SESSION = tool_state.__SESSION.data
    const [steps] = useState(showSteps)
    const history = useHistory()
    const handleCallback = data => {
        if(data.action === 'reset' || data.action === 'skip'){
            handleValidation();
        }
    }
    const handleValidation = async() => {
        const userId = window.atob(__SESSION.ainfo.aid).slice(8);
        let body = { user_id: userId, tool_id: parseInt(process.env.REACT_APP_TID_BIZ), feature_name: 'tour', 
            personalization: [
                {sf_name: 'tour', status: 1}
            ]}
        const response = await rqx.post(`<<global>>/${env() === 'local' ? 'dev' : env()}/personalization/create`, body)
        if (response.msg === 'success') {
            history.push('/biz')
            pslztn.set(null)
        }
    }
    return (
        <>
            <Joyride 
                callback={handleCallback}
                continuous
                steps={steps}
                hideBackButton
                hideCloseButton
                disableOverlayClose 
                showSkipButton={true}
                spotlightPadding={0}
                run={true}
                locale={{
                    last: 'Got it',
                    skip: 'Skip'
                }}
                styles={{
                    options: {
                      zIndex: 10000,
                      textAlign: 'left',
                      borderRadius: '50%',
                      beaconSize: 15,
                    },
                    buttonNext: {
                        backgroundColor: '#fff',
                        color: '#0070EF',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        fontSize: '14px',
                        fontWeight: 600
                    },
                    buttonSkip: {
                        padding: '10px',
                        position: 'absolute',
                        right: 100,
                        bottom: '12px',
                        fontWeight: 400
                    }
                }}
            />
        </>
    )
}
