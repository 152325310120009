import { Box } from '@mui/material'
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Setup } from './Setup'
import { Review } from './Review'
import { ToolContext } from '../../../core/context/ToolContext';
export const Profiling = () => {
    const urlParams = new URLSearchParams(useLocation().search);
    const { tool_state, home_state } = useContext(ToolContext)
    const __SESSION = tool_state.__SESSION
    const pslztn = home_state.pslztn
    const account = tool_state.account
    const [profile, setProfile] = useState({location: null, files: null})
    return (
        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' height="100%" width="100%" bgcolor="#fff">
            {
                urlParams.get('account') === 'setup' ? (
                    <Setup account={account} profile={profile} setProfile={setProfile}/>
                ) : (
                    <Review __SESSION={__SESSION} account={account} profile={profile} pslztn={pslztn}/>
                )
            }
        </Box>
    )
}