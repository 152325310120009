import { useHistory } from 'react-router-dom'
import { Box, Badge, Button, Dialog, Typography } from '@mui/material'
import cafgu from '../../../../assets/images/cafgu/Welcome.png'
import { env } from '../../../../core/Env';
import { rqx } from '../../../../core/request/API';
import { Home1, TakeExam1, MyReviewer, Leaderboard1, PreBoard1 } from '../../../../core/global/Icons';
export const WTD = ({__SESSION, pslztn}) => {
    const history = useHistory()
    const handleButton = async(type) => {
        let d = new Date()
        const strg = [{aid: __SESSION.ainfo.aid, env: env(), feature_name: `What to do`, date_added: `${d.getMonth()}${d.getUTCDate()}${d.getUTCFullYear()}`}]
        localStorage.setItem('sip_biz_session_'+__SESSION.ainfo.aid, JSON.stringify(strg))
        if(type === 'dashboard'){
            if(__SESSION.pinfo.length === 0){
                pslztn.set('welcome')
            }else{
                let getTour = await rqx.get(`<<global>>/${env() === 'local' ? 'dev' : env()}/personalization/read`, {tool_id: parseInt(process.env.REACT_APP_TID_BIZ), user_id: window.atob(__SESSION.ainfo.aid).slice(8) , ref: 'tour'})
                if(getTour.length === 0){
                    pslztn.set('tour')
                }else{
                    let fetchData = await rqx.get(`<<tool>>/${env()}/user/read`, {aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1])})
                    if(fetchData.plan.length === 0){
                        pslztn.set('wtk')
                    }else if(fetchData.reasons.length === 0){
                        pslztn.set('reasons')
                    }else{
                        pslztn.set(null)
                    }
                }
            }
        }else{
            pslztn.set(null)
            history.push(`/biz/${type}`)
        }
    }
    return (
        <Dialog open={true} PaperProps={{sx:{borderRadius: '16px', width:'550px'}}} >
            <Box display="flex" alignItems="center" justifyContent="center" sx={{background: 'linear-gradient(94.74deg, #EEEDF5 4.62%, #EAF4E8 26.42%, #FFFFFF 56.3%)',  p: '20px'}}>
                <img src={cafgu} alt="icon" width="130"/>
                <Typography color="#333333" fontSize="20px" fontWeight={700} ml="10px">What do you want to do first?</Typography>
            </Box>
            <Box display="flex" mt="30px" justifyContent="center">
                <Box component={Button} minWidth="110px" borderRadius="6px" display="flex" flexDirection="column" alignItems="center" gap="4px" py="12px" bgcolor="#DAE8DA" onClick={()=>handleButton('dashboard')}>
                    <Home1 stroke="#107038"/>
                    <Box fontSize={12} fontWeight={400} lineHeight="19px" width="100%" sx={{wordBreak: 'break-word'}} color="#107038">Dashboard</Box>
                </Box>
                <Box component={Button} minWidth="110px" borderRadius="6px" display="flex" flexDirection="column" alignItems="center" gap="4px" py="12px" bgcolor="#DAE8DA" ml="10px" onClick={()=>handleButton('take-exam')}>
                    <TakeExam1 fill="#107038" stroke="#107038"/>
                    <Box fontSize={12} fontWeight={400} lineHeight="19px" width="100%" sx={{wordBreak: 'break-word'}} color="#107038">Take Exam</Box>
                </Box>
                <Box component={Button} minWidth="110px" borderRadius="6px" display="flex" flexDirection="column" alignItems="center" gap="4px" py="12px" bgcolor="#DAE8DA" ml="10px" onClick={()=>handleButton('leaderboard')}>
                    <Leaderboard1 stroke="#107038"/>
                    <Box fontSize={12} fontWeight={400} lineHeight="19px" width="100%" sx={{wordBreak: 'break-word'}} color="#107038">Leaderboard</Box>
                </Box>
            </Box>
            <Box display="flex" mt="20px" justifyContent="center" mb="30px">
                <Badge color="primary" badgeContent="Soon">
                    <Box component={Button} minWidth="110px" borderRadius="6px" display="flex" flexDirection="column" alignItems="center" gap="4px" py="12px" bgcolor="#DAE8DA">
                        <MyReviewer fill="#107038" stroke="#107038"/>
                        <Box fontSize={12} fontWeight={400} lineHeight="19px" width="100%" sx={{wordBreak: 'break-word'}} color="#107038">My Reviewer</Box>
                    </Box>
                </Badge>
                <Badge color="primary" badgeContent="Soon">
                <Box component={Button} minWidth="110px" borderRadius="6px" display="flex" flexDirection="column" alignItems="center" gap="4px" py="12px" bgcolor="#DAE8DA" ml="30px">
                    <PreBoard1 stroke="#107038"/>
                    <Box fontSize={12} fontWeight={400} lineHeight="19px" width="100%" sx={{wordBreak: 'break-word'}} color="#107038">My Classes</Box>
                </Box>
                </Badge>
            </Box>
        </Dialog>     
    )
}