import { Box, Button, Alert, Snackbar, Typography } from '@mui/material'
import { KeyboardBackspace } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Unknown from '../../../assets/images/home/unknown-profile.png'
import { avatarstyle } from '../../../core/styling/global'
import { rqx } from '../../../core/request/API';
import { env } from '../../../core/Env';
import { CircleCheck } from '../../../core/global/Icons';
import { SnakeLoader } from '../../../core/loader/SnakeLoader'
import { S3v2 } from '../../../core/transmit/S3';
export const Review = ({__SESSION, account, profile, pslztn}) => {
    const history = useHistory()
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    let info = [
        { name:'First Name*', value: account.data.firstname },
        { name:'Last Name*', value: account.data.lastname },
        { name:'Suffix', value: account.data.suffix },
    ]
    const handleClose = () => {
        setOpen(false);
    }
    const handleProceed = async() => {
        setLoading(true);
        let imgLocation = null
        if(profile.location !== null){
            const transmit  = await S3v2(profile.files)
            account.set({...account.data, profile : transmit.location})
            imgLocation = transmit.location
        }
        let params = {aid: __SESSION.data.ainfo.aid, first_name: account.data.firstname, last_name: account.data.lastname, middle_name: '', suffix: account.data.suffix, contact_info: {contact_num: __SESSION.data.ainfo.mobile_num}, other_info: __SESSION.data.pinfo.length > 0 ? JSON.parse(__SESSION.data.pinfo[0].other_info) : {gender: '', birthdate: '', civil_status: ''}, profile: {cover: "https://pofsis-business.s3.amazonaws.com/biz-image/DefaultBG.png", profile: (imgLocation === null || imgLocation === undefined) ? "https://pofsis-business.s3.amazonaws.com/biz-image/1648461818905.png" : imgLocation}};
        let updatePinfo = await rqx.post(`<<global>>/${env()==='local'?'dev':env()}/primeinfo/${__SESSION.data.pinfo.length > 0 ? 'update' : 'create'}`, params)
        if(updatePinfo.msg === 'success'){
            let pinfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/primeinfo/read`, {aid: __SESSION.data.ainfo.aid})
            const licensure = await rqx.get(`<<tool>>/${env()}/licensure/get`, {"name": "CPA"})
			pinfo[0]['licensure'] = licensure[0].id	
            __SESSION.set({...__SESSION.data, pinfo : pinfo})
            setOpen(true)
            setTimeout(() => {
                pslztn.set('success');
                history.push('/biz')
            }, 3000);
        }
    }
    useEffect(() => {
        if (account.data.firstname  === '' || account.data.lastname === '') {
            history.push('/biz/profiling?account=setup')
        }
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <Typography color="#333333" fontSize="24px" fontWeight="bold" py={2} textAlign="center">Review Details</Typography>
            <Typography color="#525B6D" fontSize="14px" textAlign="center">Make sure all the details is correct</Typography>
            <Box borderRadius="8px" bgcolor="#FFFFFF" p={3} my={3}  width="510px">
                <Box className="c-pointer"  onClick={() => history.goBack()} >
                    <KeyboardBackspace/>
                </Box>
                <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                    <Box className={avatarstyle(account.data.profile === '' ? (profile.location === null ? Unknown : profile.location) : account.data.profile, 120, 120)} sx={{borderRadius: '100px !important'}}/>
                    <Box width="100%" mt="30px">
                        
                        {info.map((value, data) => (
                            <Box key={data}>
                                <Box fontSize="14px" mb="4px" mt="14px">{value.name}</Box>
                                <Box bgcolor="#F1F1F1" border="1px solid #E3E7EC" borderRadius="6px" p="10px 15px">
                                    <Box fontSize="14px" color={value.value === '' ? '#F1F1F1' : '#A2A3A9'}>{value.value === '' ? 'none' : value.value}</Box>    
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Button variant="contained" color="primary" sx={{padding: '8px 24px', mt:'24px'}} onClick={handleProceed} disabled={loading ? true : false}>
                        {
                            loading ? (
                                <Box height="25px" display="flex" alignItems="center" justifyContent="center"><SnakeLoader size="10px" bg="#fff" distance="0.3rem" /></Box>
                            ) : (
                                'Proceed'
                            )
                        }
                    </Button>
                </Box>
                <Snackbar open={open} anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={6000} onClose={handleClose}>
                    <Alert variant="filled"  severity="success" icon={<CircleCheck fontSize="inherit" fill="#008F5D"/>} sx={{ width: '100%', color: '#008F5D', bgcolor: '#E8F5E9', border: '1px solid #008F5D'}}>
                        User account was successfully saved
                    </Alert>
                </Snackbar>
            </Box>
        </>
    )
}