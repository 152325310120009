import { Box, Avatar } from '@mui/material';
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react';
import PSLLogo from '../../../../../assets/images/logos/logo_v1.png'
import star from '../../../../../assets/images/home/star.png'
import crown from '../../../../../assets/images/home/crown.png'
import { rqx } from '../../../../../core/request/API';
import { env } from '../../../../../core/Env';
import { convertTake} from '../Functions'
export const Index = ({__SESSION}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rank, setRank] = useState({no: 0, total: 0, points: 0})
    let p = __SESSION.pinfo.length > 0 ? (JSON.parse(__SESSION.pinfo[0].profile)) : 'no-data';
    const display = (p === 'no-data' ? PSLLogo : p.profile === '' || p.profile === undefined || p.profile === null || p.profile === 'unknown' ? PSLLogo : p.profile)
    const fetchData = async() => {
        if(__SESSION.uaccess.length > 0){
            if(__SESSION.uaccess[0].company_id !== null){
                const getRanking = await rqx.get(`<<tool>>/${env() === 'local' ? 'dev' : env()}/user/getRankings`, {company_id: __SESSION.uaccess[0].company_id, tool_id: process.env.REACT_APP_TID_BIZ, limit: 5, skip: 0, platform: 'biz'})
                if(getRanking.msg === 'success'){
                    setData(getRanking.data)
                    setLoading(false)
                }
            }
        }
        setRank({no: 0, total: 0, points: 0})
    }
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <Box borderRadius="6px" border="1px solid #E5EBF6" p="24px" mt="40px" bgcolor="#fff">
            <Box display="flex" justifyContent="space-between" mb="24px">
                <Box fontWeight="700">Leaderboard</Box>
                <Link style={{color: '#004CFF'}} href="#" to="/leaderboard">See all</Link>
            </Box>
            <Box display="flex" alignItems="center" mb="8px">
                <img src={display} height="40" width="40" alt="PSLLogo" style={{borderRadius: '100px'}}/>
                <Box fontWeight={400} color="#313131" ml="12px">
                    {
                        __SESSION.pinfo.length > 0 ? `${__SESSION.pinfo[0].first_name} ${__SESSION.pinfo[0].last_name}` : 'Unnamed Hero'
                    }
                </Box>
            </Box>
            <Box display="flex" alignItems="center" mb="24px">
                <Box color="#8496BB" fontSize="14px">My Rank <Box component="span" color="#313131" ml="8px" mr="24px">{rank.no + convertTake(rank.no)}</Box></Box>
                <Box display="flex" alignItems="center">
                    <Box color="#8496BB" fontSize="14px" >Points</Box> 
                    <Avatar src={star} alt="icon" sx={{height: 16, width: 16, ml: '8px'}}/> 
                    <Box component="span" color="#313131" ml="8px">{rank.points}</Box>
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-around" p="12px 32px" fontSize="13px" fontWeight={600} border="1px solid #E5EBF6" bgcolor="#F6F9FF" borderRadius="6px">
                <Box width="30%">Rank</Box>
                <Box width="35%">Name</Box>
                <Box width="35%">Points</Box>
            </Box>
            {
                loading ? (
                    <></>
                ) : (
                    data.map((d,i) => (
                        <Box key={i} display="flex" justifyContent="space-between" alignItems="center" padding="12px 32px">
                            <Box width="30%" fontSize="13px" fontWeight={600} mr="5px" display="flex">
                                {(i+1) + convertTake(i+1)}
                                {i===0 && <Avatar src={crown} alt="icon" sx={{height: 16, width: 16, ml: '8px'}}/> }
                            </Box>
                            <Box width="35%" display="flex" alignItems="center">
                                <img src={(JSON.parse(d.profile).profile === '' || JSON.parse(d.profile).profile === undefined || JSON.parse(d.profile).profile === null || JSON.parse(d.profile).profile === 'unknown') ? PSLLogo : JSON.parse(d.profile).profile} height="28" alt="profile" width="28" style={{borderRadius: '100px', border: i === 0 ? '2px solid #2EAE84' :   i === 1 ? '2px solid #5B8DFD' : i === 2 ? '2px solid #FF6B6B' : '2px solid #DEE2EA'}}/>
                                <Box ml="5px" fontSize="13px">{d.first_name+' '+d.last_name}</Box>
                            </Box>
                            <Box width="35%" fontSize="13px" display="flex"> 
                                <Avatar src={star} alt="icon" sx={{height: 16, width: 16, mr: '5px'}}/> 
                                {d.points}
                            </Box>
                        </Box>
                    ))
                )
            }
        </Box>
    )
}