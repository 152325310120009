import { Box } from '@mui/material';
import Unknown from '../../../../assets/images/home/unknown-reason.png'
import { UploadImage } from '../../../../core/global/Icons'
import { avatarReason } from '../../../../core/styling/global'
export const SetImage = ({setImage, image, myReasons}) => {
    const updateProfile = async(e) => {
        let file = [];
        file = e.target.files[0];
        if(file === undefined){
            
        }else{
            setImage({...image, location: URL.createObjectURL(file), name: e.target.files[0].name, type: e.target.files[0].type, files: e.target.files})
        }
    }
    return (
        <Box display="flex" justifyContent="center" mb="24px">
            <Box width="100%">
                <Box className={avatarReason((image.location === null ? (myReasons.image === null ? Unknown : myReasons.image[0].link) : image.location), '243px')} sx={{borderRadius: '17px !important'}} border="1px solid" borderColor="#fff"/>
                <Box borderRadius="8px" className="c-pointer" display="flex" justifyContent="center" width="100%" mt="20px">                    
                    <label htmlFor="prf" className="c-pointer">
                        <Box sx={{background: 'rgba(255, 255, 255, 0.24)', border: '1px solid #E5EBF6', display: 'flex', borderRadius: '6px'}} p="6px" display="flex" justifyContent="center">
                        <input type="file" id="prf" hidden name="profile" accept="image/*" style={{display: 'none'}} onChange={(e)=>updateProfile(e)} />
                            <UploadImage />  <Box ml="8px" fontSize="14px" fontWeight={500} color="#0070EF">Upload Image</Box>
                        </Box>
                    </label>
                </Box>
            </Box>
        </Box>
    )
}